import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './Envelope.css';

const Envelope = () => {
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [hasOpenedOnce, setHasOpenedOnce] = useState(false);
  const [showNextSection, setShowNextSection] = useState(false);
  const [showPhotoWall, setShowPhotoWall] = useState(false);

  const cardAnimation = useSpring({
    transform: isCardOpen ? 'translateY(0px)' : 'translateY(300px)',
    opacity: isCardOpen ? 1 : 0,
    config: { tension: 200, friction: 20 },
  });

  const handleEnvelopeClick = () => {
    setIsCardOpen(true);
    setHasOpenedOnce(true);
  };

  const handleCloseClick = () => {
    setIsCardOpen(false);
  };

  const handleContinueClick = () => {
    setShowNextSection(true);
  };

  const goToPhotoWall = () => {
    setShowPhotoWall(true);
  };

  return (
    <div>
      {!showNextSection && !showPhotoWall && (
        <div className="envelope-container">
          {!isCardOpen && (
            <div className="envelope-wrapper">
              <img
                src="/images/envelope_fechado.png"
                alt="Envelope fechado"
                className="envelope-image"
                onClick={handleEnvelopeClick}
              />
              {!hasOpenedOnce && <p className="click-to-read">Clique para ler</p>}
            </div>
          )}

          {isCardOpen && (
            <div className="card-container">
              <img
                src="/images/envelope_aberto.png"
                alt="Envelope aberto"
                className="envelope-image"
              />
              <animated.div style={cardAnimation} className="letter">
                <div className="letter-content">
                  <h1>Feliz Aniversário!</h1>
                  <p>Parabéns Erica, muitas felicidades, tudo de bom para você!! Que seu dia hoje seja muito especial, assim como você é para mim!<br />
                  Eu adoro nossa amizade, você é uma pessoa realmente incrível e precisa enxergar isso sempre, não pense que nossa amizade vai acabar, ela vai durar por anos e anos...<br />
                  Você é uma das únicas pessoas que eu realmente sinto que posso contar para tudo, e que sempre vai dar um jeitinho de me ajudar quando eu precisar, e espero que você saiba que eu também faria o mesmo por você, em qualquer situação!<br />
                  Tenho muitos momentos bons e especiais com você, fico muito feliz por ter te conhecido e estabelecido uma conexão e amizade tão boa e tão leve, quando eu tô com você o tempo voa e eu me esqueço de todos os problemas.<br />
                  Enfim só queria dizer o quanto eu gosto de você e o quão especial você é para mim! Te desejo as melhores coisas do mundo e que todos os seus sonhos se realizem!<br />
                  Feliz 20 anos, duas décadas já! Ta ficando velha em.<br />
                  E já se prepara, que semana que vem temos o RODÍZIO DE JAPA! ❤️🎉</p>

                  <button className="close-btn" onClick={handleCloseClick}>
                    X
                  </button>
                </div>
              </animated.div>
            </div>
          )}

          {hasOpenedOnce && !isCardOpen && (
            <button className="continue-btn" onClick={handleContinueClick}>
              Continuar
            </button>
          )}
        </div>
      )}

      {showNextSection && !showPhotoWall && (
        <CakeSection goToPhotoWall={goToPhotoWall} />
      )}

      {showPhotoWall && <PhotoWall />}
    </div>
  );
};

const CakeSection = ({ goToPhotoWall }) => {
  const [candlesOut, setCandlesOut] = useState(false);

  const handleCakeClick = () => {
    setCandlesOut(true);
  };

  return (
    <div className="cake-section">
      <div className="cake-wrapper" onClick={handleCakeClick}>
        <img
          src={candlesOut ? '/images/bolo_apagado.png' : '/images/bolo_acesso.png'}
          alt="Bolo de aniversário"
          className="cake-image"
        />
        {candlesOut && <div className="smoke-animation"></div>}
      </div>
      <p className="cake-text">
        {!candlesOut
          ? 'Clique para apagar as velas e faça um pedido!'
          : 'Faça um pedido!'}
      </p>
      {/* Renderize o botão sempre que candlesOut for true */}
      {candlesOut && (
        <button
          className="continue-btn"
          onClick={goToPhotoWall}
          style={{ display: 'block' }} // Garante que não está oculto
        >
          Continuar
        </button>
      )}
    </div>
  );
};


const PhotoWall = () => {
  const photos = [
    '/images/photo1.jpg',
    '/images/photo2.jpg',
    '/images/photo3.jpg',
    '/images/photo4.jpg',
    '/images/photo5.jpg',
    '/images/photo6.jpg',
  ];

  return (
    <div className="photo-wall-section">
      <h1>Mural de Fotos</h1>
      <div className="photo-grid">
        {photos.map((photo, index) => (
          <img key={index} src={photo} alt={`Foto ${index + 1}`} className="photo" />
        ))}
      </div>
    </div>
  );
};

export default Envelope;
