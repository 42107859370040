import React from 'react';
import './App.css';  // Se necessário, estilize o site
import Confetes from './components/Confetes'; // Importe o componente de confetes
import Envelope from './components/Envelope'; // Importe o componente Envelope
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Confetes /> {/* Confetes aparecem primeiro */}
        <h1>Feliz Aniversário, Erica!</h1>
        <Envelope /> {/* Adiciona o envelope interativo */}
        <Routes>
          {/* Adicione as rotas para o seu site */}
          {/* Outras rotas e componentes do seu site */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
